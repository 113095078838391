.header {
    box-sizing: border-box;
    width: 100%;
    position: fixed;
    top: 0;
    transition: top 0.6s;
    z-index: 999;
    box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.35), -1px 2px 10px 0 rgba(0, 0, 0, 0.35);
}

.navbar--hidden {
    top: -195px;
}

.header .black {
    background-color: #222;
}

.header .yellow {
    background-color: #fcd433;
    border-bottom: solid 1px #333;
}

.header .header-content {
    height: 80px;
    width: 100%;
    position: relative;
}

.header .black.header-content {
    cursor: pointer;
}

.header .header-logo {
    height: 175px;
    max-width: 100%;
}

.header .header-logo-wrapper {
    padding: 5px;
    background-color: #fcd433;
    /*border-top-right-radius: 5px;*/
    border-bottom-right-radius: 5px;
    border-bottom: solid 2px #333;
    border-right: solid 2px #333;
    position: absolute;
    z-index: 2;
}

.header .header-title {
    font-family: Autumn;
    font-size: 4rem;
    text-align: center;
    margin: 0;
    color: #fcd433;
}

.header .link-list {
    box-sizing: border-box;
    list-style: none;

    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;

    margin: 0 0 0 100px;
    padding: 0;
    height: 100%;
}

.header .link-list a {
    font-family: Autumn;
    font-size: 1.5em;
    color: #333;
    text-decoration: none;
}

.header .link-list a:hover {
    color: #333;
    text-decoration: underline;
}

.header .link-list a:active {
    color: #333;
    text-decoration: none;
}

.header .small-menu .link-list{
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%
}

.header .small-menu .link-list li{
    font-family: Autumn;
    color: #333;
    text-decoration: none;
}

.small-menu{
    width: 100%;
    height: calc(100vh - 161px);
    position: fixed;
    top: 161px;
    left: -100%;
    transition: left ease 500ms;
    background-color: #fcd433;
    z-index: 10;
}

.small-menu.open{
    left: 0;
}

@media screen and (max-width: 861px) {
    .header .link-list {
        box-sizing: border-box;
        list-style: none;

        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: flex-end;

        margin: 0;
        padding: 0;
        height: 100%;
    }

    .header .link-list li {
        font-size: 1.3em;
        padding: 0 1em;
    }
}