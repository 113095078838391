.img-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 15px;
    grid-auto-flow: dense;

    margin: 15px 0;
}

.img-gallery-wrapper {
    cursor: pointer;
    padding: 1px;
    border: solid 1px #333;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
}

.img-gallery-wrapper .img-of-gallery {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img-gallery-wrapper:first-child {
    grid-area: 1 / 1 / span 2 / span 2;
}

.img-gallery-wrapper:nth-child(6n) {
    grid-column: span 2;
    grid-row: span 2;
}

.render-more {
    width: calc(100% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    text-align: center;
    font-size: 1.4em;
    font-weight: bold;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2px;
    border: solid 1px #333;
    margin: 15px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);;

}