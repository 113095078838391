.roller{
    position: relative;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 350px;
    overflow: hidden;
    border-top: solid 1px #333;
    border-bottom: solid 1px #333;
}

.roller .roller-wall{
    position: relative;
}

.roller .scroll-button{
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    font-size: 1.3em;

    background-color: transparent;
    border: solid 1px #333;
    border-radius: 0.25em;
    padding-top: 5px;
}

.roller .shadow-element{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: transparent;
    pointer-events: none;
    box-shadow: inset 0px 0px 10px 5px rgba(255,255,255,1);
}

.roller .roller-element{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5em;
    padding: 0 2.5em;
}

.roller .roller-element .img{
    max-height: 150px;
    max-width: 100%;
}