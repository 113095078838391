@import "components/VideoContainer/video-container.css";
@import "components/TechnologyContainer/technology-container.css";
@import "components/SetupElement/setup-element.css";
@import "components/SponsorElement/sponsor-element.css";
@import "components/EventContainer/event-container.css";
@import "components/ContributorContainer/contributor-container.css";
@import "components/ContributorElement/contributor-element.css";
@import "components/ContactContainer/contact-container.css";
@import "components/FooterBar/footer-bar.css";
@import "components/TitleBar/title-bar.css";
@import "components/ProjectElement/project-element.css";
@import "components/Roller/roller.css";

@import "pages/projects/projects.css";
@import "pages/gallery/gallery.css";

@font-face {
    font-family: 'Autumn';
    src: url('Autumn.eot');
    src: url('Autumn.eot?#iefix') format('embedded-opentype'),
    url('Autumn.woff2') format('woff2'),
    url('Autumn.woff') format('woff'),
    url('Autumn.ttf') format('truetype'),
    url('Autumn.svg#Autumn') format('svg');
    font-weight: 100;
    font-style: normal;
}

body {
    background: linear-gradient(to bottom, rgba(206, 232, 229, 0.95) 9%, rgba(213, 229, 211, 0.75) 19%, rgba(254, 212, 96, 0.75) 82%, rgba(255, 211, 92, 0.75) 84%, rgba(241, 114, 34, 0.75) 100%);
}

.fullSize {
    box-sizing: border-box;
    background-color: transparent;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    z-index: 3;

}

.background {
    background-image: url("res/backgroundBase.svg");
    background-color: transparent;
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}

.container-wrapper {
    position: absolute;
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 10;

    padding-top: 161px;
}

.container-wrapper .container-height {
    width: 100%;
    max-width: 860px;
    margin-bottom: 134px;
    background-color: white;
    border-left: solid 1px #333;
    border-right: solid 1px #333;
    border-bottom: solid 1px #333;
    padding: 0 0 0 0;
    box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.35), -1px 2px 10px 0 rgba(0, 0, 0, 0.35);

    overflow: hidden;
}

.container-height.gallery {
    box-shadow: none;
    border: none;
    background: transparent;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2em 1em 2em;
}

.container.justify-start {
    justify-content: flex-start;
}

.container.no-bottom {
    padding-bottom: 0;
}

.container h2 {
    font-family: Autumn;
    font-size: 2em;
    margin: 0;
}

.container .sub-header {
    font-family: Autumn;
}

.container .header-line {
    background-color: #fcd433;
    box-shadow: 0 0px 6px 0px #333;
    width: calc(100% + 2em);
    padding: 0.5em 0 0.5em 2em;
    position: relative;
    z-index: 10;
}

.text {
    font-size: 1.5em;
    margin: 0.5em 0;
    text-align: center;
}

.text.text-margin {
    margin: 0.5em;
}

.wrap-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
}

.wrap-container .header-line {
    width: 100%;
}

.ab {
    background-image: url("res/backgroundBase.svg");
}

.ab1 {
    background-image: url("res/abc1.svg");
}

.ab2 {
    background-image: url("res/abc2.svg");
}

.ab3 {
    background-image: url("res/abc3.svg");
}

.ab4 {
    background-image: url("res/abc4.svg");
}

.ab5 {
    background-image: url("res/abc5.svg");
}

.black-container {
    width: calc(100% - 1em);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    font-family: Autumn;

    background: #222;
    color: #fcd433;

    font-size: 2.5em;
}

.black-container a {
    color: #fcd433;
    text-decoration: none;
}

.black-container a:hover{
    text-decoration: underline;
}


.no-padding-bottom {
    padding-bottom: 0 !important;
}

.clear-btn {
    background: transparent;
    border: none;
}

.img {
    max-height: 250px;
    max-width: 250px;
}

.flex-wrapper {
    box-sizing: border-box;
    padding: 1.4em 2.6em;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 1 auto;

    height: 100%;
}

.flex-wrapper.border {
    border-top: solid 1px #333;
    border-bottom: solid 1px #333;
}

.flex-wrapper .description {
    padding: 1em 2em;
    height: 100%;
}

.flex-wrapper .img {
    margin: 1em;
}

.flex-wrapper .description .description-text {
    padding: 0.5em;
    font-size: 1.2em;
}

.flex-wrapper .description h2 {
    font-size: 1.5em;
    margin-top: 0;
    padding-top: 0;
}

.flex-wrapper.fix-padding-left {
    padding-left: 0.25em
}

.flex-wrapper.fix-padding-right {
    padding-right: 0.25em
}

.full-width {
    width: 860px;
    position: relative;
}

.dot-group {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0.5em 0 0 0;
}

.left-btn, .right-btn {
    position: absolute;
    font-size: 2em;
    height: 100%;

    display: flex;
    align-items: center;
    top: 0
}

.left-btn {
    left: 0;
}

.right-btn {
    right: 0;
}

.relative-block {
    position: relative;
}

.animation-base {
    position: fixed;
    animation-duration: 400s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
}

.animation1 {
    top: 150px;
    left: -10vw;
    animation-name: animation-one;


    background-image: url("res/cloud1.svg");

    height: 201px;
    width: 499px;
    z-index: 0;
}

.animation2 {
    position: fixed;
    top: 270px;
    right: -10vw;
    animation-name: animation-two;

    background-image: url("res/cloud2.svg");

    height: 234px;
    width: 390px;
    z-index: 0;
}


@keyframes animation-one {
    from {
        left: -10vw;
    }
    to {
        left: 80vw;
    }
}

@keyframes animation-two {
    from {
        right: -10vw;
    }
    to {
        right: 95vw;
    }
}

.placeholder {
    height: 2em;
}



.link-icon {
    max-height: 1em;
    max-width: 1em;
    margin-right: 0.25em;
}

.leaflet-container {
    height: 100%;
    width: 100%;
}

.line {
    margin: 0.25em;
}

@media screen and (min-width: 862px ) {
    .sm-show {
        display: none !important;
    }
}

@media screen and (max-width: 861px) {

    .text{
        font-size: 1.3em;
    }
    .sm-hidden {
        display: none !important;
    }

    .sm-show {
        display: inherit;
    }

    .container-wrapper {
        align-items: flex-start;
    }

    .full-width {
        width: 100%;
    }

    .container-wrapper .container-height {
        margin-bottom: 0;
        box-shadow: none;
        border: none;
    }

    .container-height{
        display: flex;
        flex-direction: column;
    }

    .container-height .contact{
        order: 1
    }
    .container-height .about{
        order: 2
    }
    .container-height .technologies{
        order: 3
    }
    .container-height .setup{
        order: 4
    }
    .container-height .sponsors{
        order: 5
    }
    .container-height .events{
        order: 6
    }
    .container-height .contributors{
        order: 7
    }

    .events .header-line{
        margin-bottom: 20px;
    }

    .events .black-container{
        margin-top: 20px;
    }

    .line {
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .displayed-info div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .displayed-info span {
        text-align: center;
    }

    .black-container{
        font-size: 1.79em;
    }
}

.image-gallery .image-gallery-content .image-gallery-slide .image-gallery-image{
    max-height: 255px;
}

.image-gallery .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image{
    max-height: calc(100vh - 120px);
}