.contact-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contact-wrapper .map-wrapper {
    width: calc(100% + 4em);
    height: 400px;
    position: relative;
    z-index: 5;
}

.contact-wrapper .img-wrapper {
    width: 80px;
    margin: 0.5em;
}

.contact-wrapper .img-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.contact-wrapper .info-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
}

.contact-wrapper .displayed-info {
    margin: 0.5em;
}

.contact-wrapper .displayed-info .label {
    font-weight: bold;
}