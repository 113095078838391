.cont-container {
    margin: 1em 0;
}

.cont-container {
    display: flex;
    align-items: flex-start;
    width: calc(100% + 4em);
    margin: 0.25em 0;
}

.cont-wrapper {
    display: flex;
    align-items: flex-start;
}

.cont-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.cont-img {
    width: 50px;
    height: 50px;
}

.cont-container.reverse {
    justify-content: flex-end;
}

.cont-container.reverse .cont-icon {
    transform: scaleX(1)
}

.cont-container .cont-icon {
    transform: scaleX(-1);
}

.cont-container .cont-icon.small {
    height: 75px;
    width: 30px;
}

.cont-container .cont-icon.medium {
    height: 100px;
    width: 50px;
}

.cont-container .cont-icon.big {
    height: 150px;
    width: 75px;
}

.cont-container .cont-icon img {
    max-height: 100%;
    max-width: 100%;
}

.reverse .cont-info div {
    text-align: right;
}

.cont-info {
    margin: 0 1em;
}

.cont-info .cont-name {
    font-size: 1.5em;
}

.cont-info .cont-name.small {
    font-size: 1.5em;
}

.cont-info .cont-name.medium {
    font-size: 2em;
}

.cont-info .cont-name.big {
    font-size: 2.5em;
}

.cont-info .cont-short {
    font-size: 0.9em;
}

.cont-info .cont-description {
    font-size: 1em;
}

.cont-info .cont-description.small {
    font-size: 1em;
}

.cont-info .cont-description.medium {
    font-size: 1.25em;
}

.cont-info .cont-description.big {
    font-size: 1.5em;
}

@media screen and (max-width: 861px) {
    .cont-container {
        margin: 0.75em;
        width: 100%;
        justify-content: center;
    }

    .cont-container.reverse {
        justify-content: center;
    }

    .reverse .cont-info div {
        text-align: center;
    }

    .cont-info div {
        text-align: center;
    }

    .cont-info .cont-name {
        font-size: 1em;
    }

    .cont-info .cont-name.medium {
        font-size: 1.3em;
    }

    .cont-info .cont-name.big {
        font-size: 1.5em;
    }

    .cont-info .cont-short {
        font-size: 0.8em;
    }

    .cont-info .cont-description {
        font-size: 0.8em;
    }

    .cont-info .cont-description.medium {
        font-size: 0.8em;
    }

    .cont-info .cont-description.big {
        font-size: 0.8em;
    }
}
