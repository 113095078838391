.sponsor-container {
    margin: 1em 0;
}

.sponsor-container {
    background-color: #fcd433;
    color: #222;
    box-shadow: 2px 0 6px -1px #333, -2px 0px 6px -1px #333;
    min-height: 300px;
    padding: 0.5em;
    min-width: 811px;
}

.sponsor-container:nth-child(odd) {
    margin-left: calc(-2em - 1px);
}

.sponsor-container:nth-child(even) {
    margin-right: calc(-2em - 1px);
}

.sponsor-container.border-radius-right {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.sponsor-container.border-radius-left {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

@media screen and (max-width: 861px) {
    .sponsor-container {
        background-color: #fcd433;
        color: #222;
        box-shadow: none;
        min-width: auto;
        min-height: auto;
    }

    .sponsor-container.border-radius-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .sponsor-container.border-radius-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .sponsor-container:nth-child(odd) {
        margin-left: 0;
    }

    .sponsor-container:nth-child(even) {
        margin-right: 0;
    }

    .sponsor-container .flex-wrapper {
        flex-direction: column;
        padding: 0;
        align-items: center;
    }

    .sponsor-container .flex-wrapper.fix-padding-left {
        padding-left: 0
    }

    .sponsor-container .flex-wrapper.fix-padding-right {
        padding-right: 0
    }

    .sponsor-container .img {
        order: 0;
    }

    .sponsor-container .description {
        order: 1;
    }
}