.event-container {
    width: calc(50% - 20px);
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 2.5em;
    box-sizing: border-box;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0 5px 2px #333;
}

.event-container h2 {
    color: black;
    text-decoration: none;
}

.event-container h2 a {
    color: black;
    text-decoration: none;
}

.event-url {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-end;
    cursor: pointer;
    text-decoration: none;
}

.event-url {
    color: black;
}

.event-url:hover {
    text-decoration: underline;
}

.event-short{
    height: 93px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.event-short.auto{
    overflow: initial;
}

.event-short.missing{
    cursor: initial;
}

.event-short.auto.missing{
    overflow: hidden;
    cursor: initial;
    height: 93px;
}

.event-short.pointer{
    cursor: pointer;
}

.event-short.auto .event-short-text-wrapper{
    overflow: auto;

    background-color: white;
    height: 360px;

    position: relative;
    z-index: 10;
}

.event-short .read-more {
    width: 100%;
    padding-top: 12px;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
    color: #333;
    text-align: center;
    position: absolute;
    bottom: 0;
}

.size-gallery{
    height: 255px;
    margin-top: 5px;
}

.size-gallery .image-gallery-slides{
    max-height: 250px
}

@media screen and (max-width: 861px) {
    .event-container {
        width: calc(100% - 20px);
    }
}

@media all and (display-mode: fullscreen) {
    .size-gallery .image-gallery-slides{
        max-height: initial;
    }
}