.sum-container {
    width: calc(100% + 4em);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
}

.sum-container .vreeclimber {
    width: 100%;
    font-size: 4em;
    background: #222;
    color: #fcd433;
    font-family: Autumn;
    text-align: center;
    padding: 0 0 0.25em 0;
}

.sum-container .vreeclimber-description {
    width: 100%;
    font-size: 2em;
    background: #222;
    color: #fcd433;
    font-family: Autumn;
    text-align: center;
    padding: 0.25em 0 0 0;
}

.sum-container .vreeclimber-description .equal-icon {
    font-size: 0.85em;
}