.video-container {
    margin-bottom: -4px;
    max-width: 100%;
}

.video-container video {
    border-bottom: solid 2px #333;
    border-top: solid 1px #333;
}

video {
    max-width: 100%;
}