.small-contributors {
    margin-top: 2em;
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

.small-contributors .small-contributor {
    width: 33%;
    text-align: center;
    padding: 0.5em 0;
}

.small-contributors .small-contributor:hover {
    text-decoration: underline;
}

@media screen and (max-width: 861px) {
    .small-contributors .small-contributor{
        width: 50%;
    }
}