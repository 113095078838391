.footer {
    width: 100%;
    height: 150px;
    background: #222;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    box-shadow: 1px -2px 10px 0 rgba(0, 0, 0, 0.35), -1px -2px 10px 0 rgba(0, 0, 0, 0.35);
    position: relative;
    z-index: 12;
}

.footer .footer-small-logo-wrapper{
    display: flex;
    height: 100%;
    min-width: 170px;
}

.footer .logo {
    height: 100%;
    max-width: 100%;
}

.footer .footer-container {
    width: 100%;
    height: 100%;
    padding: 15px;

    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
}

.footer .link-list, .footer .info-list {
    box-sizing: border-box;
    list-style: none;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    justify-content: space-around;

    margin: 0;
    padding: 0;
    height: 100%;
}

.footer .link-list a {
    font-family: Autumn;
    color: #fcd433;
    text-decoration: none;
}

.footer .link-list a:hover {
    color: #fcd433;
    text-decoration: underline;
}

.footer .link-list a:active {
    color: #fcd433;
    text-decoration: none;
}

.footer .info-list {
    font-family: Autumn;
    font-size: 1.3em;
    color: #fcd433;
    text-decoration: none;
}

@media screen and (max-width: 861px) {
    .footer {
        box-shadow: none;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer .footer-small-logo-wrapper {
        height: 250px;
        display: flex;
    }

    .footer .footer-container {
        flex-direction: column;
    }

    .footer .footer-container .link-list {
        margin: 1em 0;
        font-size: 1.5em;
    }

    .footer .footer-container .link-list li {
        padding: 0.25em 0;
    }

    .footer .footer-container .info-list li {
        text-align: right;
        width: 100%;
    }
}