.project-element {
    width: 100%;

    background-color: white;

    border: solid 1px #333;
    border-radius: 5px;

    box-sizing: border-box;
    padding: 1em;
    grid-area: span 2 / span 2;
}

.project-element .image-wrapper {
    width: 50%;
    padding: 1em;
    box-sizing: border-box;
    float: left;
}

.project-element .image-wrapper img {
    max-width: 100%;
    max-height: 100%;
}

.project-element .project-name {
    font-weight: bold;
    font-size: 1.4em;
}

.project-element .project-description {
    font-size: 0.85em;
    padding: 0.25em
}

@media screen and (max-width: 861px) {
    .project-element {
        grid-area: span 3 / span 1;
    }
}