.setup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.setup-icon-container {
    position: relative;
    width: 7.5em;
    height: 7.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em;
    flex: 1 1 auto;
}

.setup-icon {
    position: absolute;
    max-width: 3.5em;
    max-height: 3.5em;
}

.setup-icon-background {
    position: absolute;
    max-width: 7.5em;
    max-height: 7.5em;
}

@media screen and (max-width: 861px) {
    .setup-wrapper {
        flex-direction: column;
    }

    .setup-wrapper .setup-icon-container {
        order: 0;
    }

    .setup-wrapper .text {
        order: 1;
    }

    .setup-icon-container {
        width: 5.5em;
        height: 5.5em;
    }

    .setup-icon {
        max-width: 3em;
        max-height: 3em;
    }

    .setup-icon-background {
        position: absolute;
        max-width: 5.5em;
        max-height: 5.5em;
    }
}